import { useFetcher, useLoaderData, useNavigate } from '@remix-run/react'
import { bookAuthenticator as authenticator } from '~/modules/auth/auth.server.ts'
import { getSession, commitSession } from '~/modules/auth/auth-session.server.ts'
import constants from '~/utils/constants'
import { Card } from '~/components/ui/card'
import { Fr8LogoSvg } from '~/components/assets/svg/Fr8LogoSvg'
import React from 'react'
import { toast } from '~/components/ui/use-toast'
import { Checkbox } from '~/components/ui/checkbox'
import { Button } from '~/components/ui/button'
import util from '~/utils/util'
import ReCAPTCHA from 'react-google-recaptcha'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import ClientOnly from '~/components/composed/client-only'
import { Form } from '~/components/ui/form'
import InputController from '~/components/ui/custom_ui/inputController'
import { LoaderFunctionArgs } from '@remix-run/router'
import type { ActionFunctionArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import CampaignInit from '~/modules/auth/CampaignInit'
import { Loader2 } from 'lucide-react'
import { sendOTPToMobile } from '~/.server/auth/loging'

export async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URLSearchParams(request.url.split('?')[1])
  const campaignParam = searchParams.toString()
  const campaignData = campaignParam.includes('utm') ? campaignParam : ''
  await authenticator.isAuthenticated(request, {
    successRedirect: constants.urls.indent,
  })

  const cookie = await getSession(request.headers.get('cookie'))
  const authError = cookie.get(authenticator.sessionErrorKey)
  // Commit session to clear any `flash` error message.
  return json({ authError, campaignData } as const, {
    headers: {
      'set-cookie': await commitSession(cookie),
    },
  })
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.json()
  let result = null
  const mobile = formData?.mobile // Get the value of the mobile field from the form data
  const token = formData?.token
  //   if (mobile) {
  result = await sendOTPToMobile(mobile, token)

  return result
}

const loginFormSchema = z.object({
  mobile: z.string().refine(util.isValidMobile, 'Enter Valid Mobile Number'),
  token: z.string(),
})
const loginDefaultValues = { mobile: '', token: '' }

export default function Login() {
  const { authError, campaignData } = useLoaderData<typeof loader>()
  const navigate = useNavigate()

  const [isFocused, setIsFocused] = React.useState(false)
  const [terms, setTerms] = React.useState<boolean>(false)
  const [isMobile, setIsMobile] = React.useState<boolean>(false)
  const recaptchaRef = React.useRef<ReCAPTCHA>(null)
  const fetcher = useFetcher()
  const isSubmitting = fetcher.state === 'submitting'

  React.useEffect(() => {
    if (campaignData) {
      localStorage.setItem('utm_source', campaignData)
    }
  }, [])

  const form = useForm<z.infer<typeof loginFormSchema>>({
    mode: 'onChange',
    resolver: zodResolver(loginFormSchema),
    defaultValues: loginDefaultValues,
  })

  type ValidationSchema = z.infer<typeof loginFormSchema>
  const onSubmit: SubmitHandler<ValidationSchema> = (values) => {
    recaptchaRef.current?.executeAsync().then((_token) => {
      form.setValue('token', _token || '') // Append the reCAPTCHA token to the form data
      fetcher.submit(
        { ...values, token: _token },
        {
          method: 'post',
          encType: 'application/json',
        },
      )
    })
  }
  // Redirect to verify screen with mobile number
  React.useEffect(() => {
    if (fetcher.data) {
      const verifyUrl = `${constants.urls.verify}?mobile=${form.getValues('mobile')}`
      const data: any = fetcher.data
      if (data?.status) {
        navigate(verifyUrl, { state: data })
      } else {
        toast({
          variant: 'destructive',
          title: 'Error Occured!',
          description: `${data?.result?.message || ''}`,
        })
      }
    }
  }, [fetcher.data, navigate])

  React.useEffect(() => {
    if (authError) {
      toast({
        variant: 'destructive',
        title: 'Error Occured!',
        description: `${authError?.message}`,
      })
    }
  }, [authError])

  React.useEffect(() => {
    if (util.isMobileRegex.test(navigator.userAgent)) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  return (
    <div className="flex min-h-screen items-center justify-center p-4">
      <div className="max-h-max max-w-sm">
        <Card className="max-h-max space-y-3 p-6">
          <div className="flex justify-center">
            <Fr8LogoSvg
              className={`${isFocused && isMobile ? 'logo-hide ' : 'logo-show'}`}
            />
          </div>
          <h1 className="text-5xl">India&apos;s largest truck booking platform</h1>
          <h3 className="text-subline">Register and get truck within a minute</h3>
        </Card>
        <Form {...form}>
          <form
            method="post"
            onSubmit={form.handleSubmit(onSubmit)}
            className="mt-4 flex w-full flex-col gap-2 space-y-4"
            autoComplete="off">
            <div className="flex flex-col">
              <label htmlFor="mobile" className="sr-only">
                Enter Mobile
              </label>
              <InputController
                control={form.control}
                placeholder="Enter Mobile"
                name="mobile"
                type="tel"
                autoComplete="on"
                required
                maxlength={10}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                prefix={<p className="text-xl">+91</p>}
                className="text-xl"
                containerStyle={'h-12'}
              />
            </div>
            <div className="flex justify-center space-x-2">
              <Checkbox
                id="terms"
                onCheckedChange={(checked) => setTerms(checked as boolean)}
              />
              <div className="grid gap-1 leading-none">
                <label
                  htmlFor="terms"
                  className="text-lg font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                  Agree to the{' '}
                  <a
                    className="text-lg font-medium"
                    target="_blank"
                    href={constants.webUrls.terms}>
                    Terms & Conditions
                  </a>
                </label>
              </div>
            </div>
            <Button
              disabled={!terms || isSubmitting}
              type="submit"
              size={'lg'}
              className="text-2xl font-normal">
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Please wait
                </>
              ) : (
                'Continue'
              )}
            </Button>
            <ClientOnly>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={import.meta.env.VITE_CAPTCHA_SITE_KEY}
                size="invisible"
              />
            </ClientOnly>
          </form>
        </Form>
      </div>
      <CampaignInit />
    </div>
  )
}
